import { ContactShadows, Text, Float, Html} from '@react-three/drei'
import { Perf } from 'r3f-perf'
import { Suspense } from 'react'
import useViews from './stores/useViews'
import { useThree } from '@react-three/fiber'

export default function Room({model}) {

    const currentPhase = useViews( state => state.phase )

    const { viewport } = useThree()

   
    model.scene.traverse((child) => {

        if (child.isMesh) {
            child.castShadow = true
            child.receiveShadow = true
        }
    })

    if(currentPhase === 'VIEW_STUDIO') {
        model.scene.traverse((child) => {
            //5D5483
            
            if(child.material && child.material.name == 'Walls'){
                child.material.color.setHex(0x5D5483)
            }

            if(child.material && child.material.name == 'Floor'){
                child.material.color.setHex(0x836246)
            }
            
        }
    )}
    if(currentPhase === 'VIEW_BEDROOM') {
        model.scene.traverse((child) => {
            
            
            if(child.material && child.material.name == 'Walls'){
                child.material.color.setHex(0xE4E7CB)
            }

            if(child.material && child.material.name == 'Floor'){
                child.material.color.setHex(0xB5A192)
            }
            
        }
    )}

    function toggleAudioMute() {
        var muteCheckbox = document.getElementById("muteCheckbox");
        var allAudio = document.getElementsByTagName("audio");
        for (var i=0; i<allAudio.length; i++) {
          allAudio[i].muted = muteCheckbox.checked;
        }
      }
      



    return <>

        <ContactShadows 
            position={[0, -1, 0]} 
            resolution={ 128 }
            far={ 10 }
            opacity={ 0.5 }
            blur={ 1 }
            frames={ 1 }
        />

        

        {/* <Perf position="top-left" /> */}


        <pointLight 
            castShadow 
            position={ [ 0, 4, 0 ] } 
            intensity={ 0.4 } 
            shadow-normalBias={ 0.03 } 
            shadow-mapSize={ [ 1024 * 2, 1024 * 2 ] }
            shadow-camera-near={ 1 }
            shadow-camera-far={ 6 }
            shadow-camera-top={ 5 }
            shadow-camera-right={ 5 }
            shadow-camera-bottom={ -5 }
            shadow-camera-left={ -5 }
            color="#eeeee4"  />

        <ambientLight intensity={ 0.1} />

        <Float
            floatIntensity={1}
            floatingRange={[-0.05,0.01]}
        >
            <Text
                font='./fonts/bangers/bangers-v20-latin-regular.woff'
                fontSize={0.4}
                position={[-4.2, 2.0, 0.75 ]}
                rotation-y={-0.2}
                maxWidth={ 2 }
                textAlign='center'               
            >
                Prathik Karanth 
            </Text>
        </Float>

        

        <Suspense fallback={null}>
            <primitive object={model.scene} />
        </Suspense>
    </>
}