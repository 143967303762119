import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";


export default create(subscribeWithSelector((set) => {
    return {
        phase: "VIEW_BEDROOM",

        bedroom: () => {
            
            set(() => {
                return {phase: "VIEW_BEDROOM"} 
            })
        },

        studio: () => {
            
            set(() => {
                return { phase: "VIEW_STUDIO" } 
            })
        },

        profile: () => {
            
            set(() => {
                return { phase: "VIEW_PROFILE" } 
            })
        },

        bedroomTransition: () => {
            
            set(() => {
                return { phase: "VIEW_GOTO_BEDROOM" } 
            })
        },
        
        studioTransition: () => {
            
            set(() => {
                return { phase: "VIEW_GOTO_STUDIO" } 
            })
        },

        music: () => {
            
            set(() => {
                return { phase: "VIEW_MUSIC" } 
            })
        }

    }
}))