import { Canvas, useFrame } from "@react-three/fiber";
import { Suspense, useEffect, useState } from "react";
import Experience from "./Experience.jsx";
import LoadingScreen from "./LoadingScreen.jsx";
import useViews from './stores/useViews'

const audio = new Audio("./sounds/bgmusic.wav");



function App() {
  const [start, setStart] = useState(false);

  const currentPhase = useViews( state => state.phase )

  useEffect(() => {
    if (start) {
      audio.play();
      audio.loop = true;
    }
  }, [start]);

  const [muted, setMuted] = useState(false);

  function toggleAudioMute(e) {
    setMuted(!muted)
    audio.muted = !audio.muted;

  }
  console.log('mute status: ', muted)


  return (

    <>
      <Canvas
            shadows
            
            camera={ {
                fov: 65,
                near: 0.1,
                far: 300,
                position: [ -2.9, 3.3, 6 ],
                
            } }
            
        >
            <Suspense fallback={null}>
                <Experience started={start} />
            </Suspense>
                    
        </Canvas>
        <LoadingScreen started={start} onStarted={() => setStart(true)} />

        {/* AudioCheckbox */}
        {(currentPhase === 'VIEW_BEDROOM' || currentPhase === 'VIEW_STUDIO') ?
            
            <div className="container">
                <h1 className='text audio'>Music</h1>
                <input id="muteCheckbox" type='checkbox' checked={!muted} onChange={ toggleAudioMute }></input>
            </div>
                       
            : null
        }
        
    </>
  );
}

export default App;