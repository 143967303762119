import { useRef, useState, useEffect } from 'react'
import { useFrame} from '@react-three/fiber'
import { useAnimations, Html} from '@react-three/drei'
import * as THREE from 'three'
import { Suspense } from 'react'
import { gsap } from 'gsap'
import useViews from './stores/useViews'

export default function Studio({model}) {

    const bedroom = useViews( (state) => { return state.bedroom} )
    const music = useViews( (state) => { return state.music} )
    const studio = useViews( (state) => { return state.studio} )

    const iframeRef = useRef()

    model.scene.traverse((child) => {

        if (child.isMesh) {
            child.castShadow = true
            child.receiveShadow = true
        }
    })

    const audioRef = useRef();

    const playSound = () => {
        
        if(audioRef.current.paused){
            audioRef.current.play();
        }        
    }

    function dampedParallax(cursor, theta) {
        let target_theta = cursor.x * 0.5;

        const THETA_CLAMP_AMOUNT = 0.1;
        target_theta = Math.min(Math.max(target_theta, -THETA_CLAMP_AMOUNT), THETA_CLAMP_AMOUNT)

        const d_theta = target_theta - theta;
        theta += d_theta * 0.05

        if(d_theta < 0.0) {
            theta = Math.max(theta, target_theta)
        } else if(d_theta > 0.0) {
            theta = Math.min(theta, target_theta)
        }  
        return theta;
        
    }

    function parallaxEffect(targetPosition, currentLookAt)
    {
        const updatedTheta = dampedParallax(mouse, theta)
        setTheta(updatedTheta)

        const cameraFacingVec = new THREE.Vector3().subVectors(targetPosition, currentLookAt)
        const distanceBetweenCamAndLookAt = cameraFacingVec.length()
        cameraFacingVec.normalize()
        const upVec = new THREE.Vector3(0, 1, 0)
        const rightVec = new THREE.Vector3().crossVectors(cameraFacingVec, upVec)
        rightVec.normalize();

        const currentParallaxCamPosition = new THREE.Vector3()

        currentParallaxCamPosition.set(currentLookAt.x, currentLookAt.y, currentLookAt.z)
        currentParallaxCamPosition.addScaledVector(rightVec,        Math.sin(updatedTheta) * distanceBetweenCamAndLookAt)
        currentParallaxCamPosition.addScaledVector(cameraFacingVec, Math.cos(updatedTheta) * distanceBetweenCamAndLookAt)

        setCurrentCamPosition(currentParallaxCamPosition)
        
    }

    const VIEW_INTO_STUDIO = 0;
    const VIEW_MUSIC = 1;
    const VIEW_STUDIO = 2;
    const VIEW_GOTO_STUDIO = 3;
    const VIEW_GOTO_BEDROOM = 4;
    const VIEW_BEDROOM = 5;

    const [previousView, setPreviousView] = useState(-1)
    const [currentView, setCurrentView] = useState(VIEW_INTO_STUDIO)
    const [currentLookAt, setCurrentLookAt] = useState(new THREE.Vector3(0, 0, 0))
    const [currentCamPosition, setCurrentCamPosition] = useState(new THREE.Vector3(-5.9, 3.3, 6))
    const [currentBgColor, setCurrentBgColor] = useState(new THREE.Color('#49403c'))

    const [isVisible, setIsVisible] = useState(true)

    const animations = useAnimations(model.animations, model.scene)
 
    const [mouse, setMouse] = useState({x:0, y:0});
    const [theta, setTheta] = useState(0.0)



    const handleMouseMove = (evt) => {
        setMouse({
            x: (evt.clientX / window.innerWidth) * 2 - 1,   
            y: -(evt.clientY / window.innerHeight) * 2 + 1    
        })
    }


    useEffect(() => {
        
        window.addEventListener('mousemove', handleMouseMove);
    
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        }

    }, []);

    
    useEffect(() => {
        
        
        for(const name in animations.actions) {
            // check if name ends with "Inflate"
            if(name.endsWith("Inflate")){
                
                const action = animations.actions[name]
                
                action.repetitions = 1
                action.clampWhenFinished = true
                action.play()
                
            }
        }
    }, []);
    
    useFrame((state, delta) => {

        let targetPosition;
        let targetLookAtVec;
        let newBgColor = new THREE.Color()

        switch(currentView) {
            case VIEW_INTO_STUDIO:
                targetPosition = new THREE.Vector3(-2.9, 3.3, 6)
                targetLookAtVec = new THREE.Vector3(0, 0, 0)
                
                if(previousView !== VIEW_INTO_STUDIO) {
                    const cameraPositionGsap = currentCamPosition.clone();
                    gsap.to(cameraPositionGsap, {
                        x: targetPosition.x,
                        y: targetPosition.y,
                        z: targetPosition.z,
                        duration: 1.5,
                        ease: 'power4.inOut', 
                        onUpdate: () => { 
                            setCurrentCamPosition(cameraPositionGsap)
                        },
                        onComplete: () => {
                            setCurrentView(VIEW_STUDIO)
                        }
                    })
                }
                newBgColor.lerpColors(currentBgColor, new THREE.Color('#222430'), 0.05) 
                setCurrentBgColor(newBgColor)

                break;
            case VIEW_STUDIO:
                for(const name in animations.actions) {
                    const action = animations.actions[name]
        
                    console.log(action)
                    if(name.endsWith("Boom")){
                        
                        
                        // action.repetitions = 1
                        action.clampWhenFinished = true
                        action.play()
                    } 

                    studio()
                }
                
                targetPosition = new THREE.Vector3(-2.9, 3.3, 6)
                targetLookAtVec = new THREE.Vector3(0, 0, 0)
                parallaxEffect(targetPosition, currentLookAt)
                break;
            case VIEW_MUSIC:
                targetPosition = new THREE.Vector3(0.2, 1.0, 0.9)
                targetLookAtVec = new THREE.Vector3(0.9, 1.0, 0.9)

                gsap.to(currentCamPosition, {
                    x: targetPosition.x,
                    y: targetPosition.y,
                    z: targetPosition.z,
                    duration: 1,
                    ease: 'power4.out'
                })

                gsap.to(currentLookAt, {
                    duration: 1,
                    x: targetLookAtVec.x,
                    y: targetLookAtVec.y,
                    z: targetLookAtVec.z,
                    onComplete: () => {
                        
                    },
                    ease: 'power4.out'                
                })

                music()
                break;
            case VIEW_GOTO_STUDIO:
                targetPosition = new THREE.Vector3(-2.9, 3.3, 6)
                targetLookAtVec = new THREE.Vector3(0, 0, 0)
                if(previousView !== VIEW_GOTO_STUDIO) {
                    const gsapCameraPosition = currentCamPosition.clone();
                    gsap.to(gsapCameraPosition, {
                        x: targetPosition.x,
                        y: targetPosition.y,
                        z: targetPosition.z,
                        duration: 1,
                        onUpdate: function() {
                            setCurrentCamPosition(gsapCameraPosition.clone())
                        },
                        ease: 'power4.out'
                    })
    
                    const gsapLookAt = currentLookAt.clone();
                    gsap.to(gsapLookAt, {
                        duration: 1,
                        x: targetLookAtVec.x,
                        y: targetLookAtVec.y,
                        z: targetLookAtVec.z,
                        onUpdate: function() {
                            setCurrentLookAt(gsapLookAt.clone())
                        },
                        ease: 'power4.out'
                    })
    
                    
                    setTheta(0.0)
                }
                

                if(previousView !== VIEW_GOTO_STUDIO) {
                    setTimeout(() => {
                        setCurrentView(VIEW_STUDIO)
                    }
                    , 1000)
                }


                break;

            case VIEW_GOTO_BEDROOM:
                targetPosition = new THREE.Vector3(-5.9, 3.3, 6)
        
                const homePosition = new THREE.Vector3(-2.9, 3.3, 6)
                const gsapCameraPosition = currentCamPosition.clone();

                gsap.to(gsapCameraPosition, {
                    x: targetPosition.x,
                    y: targetPosition.y,
                    z: targetPosition.z,
                    duration: 1,
                    ease: 'power4.out',
                    onUpdate: function() {
                        setCurrentCamPosition(gsapCameraPosition.clone())
                    }
                })

                playSound()
                for(const name in animations.actions) {
                    const action = animations.actions[name]
                    if(name.endsWith("Deflate")){
                        action.play()
                        action.repetitions = 1
                        action.clampWhenFinished = true
                    } else {
                        // kills all inflate animations
                        action.stop()
                        
                    }
                }

                setTimeout(() => {
                    setCurrentView(VIEW_BEDROOM)
                }, 1000)
                
                break;
            case VIEW_BEDROOM:
                setTimeout(() => {
                    setIsVisible(false)
                }, 700)

                bedroom()
                break;

        }

       

        state.camera.position.x = currentCamPosition.x
        state.camera.position.y = currentCamPosition.y
        state.camera.position.z = currentCamPosition.z
        state.camera.lookAt(currentLookAt)
        state.camera.updateProjectionMatrix()
          
        setPreviousView(currentView)


    })

    return <>

        <color attach="background" args={[currentBgColor]} />
        <Suspense>
            {
                isVisible ?
                <primitive object={ model.scene} /> 
                : null
            }
            <Html
                    ref={ iframeRef }
                    position={ [ 1.0, 1.04, 0.94 ] }
                    transform
                    wrapperClass='musicScreen'
                    distanceFactor={ 0.25 }
                    rotation={[0, - Math.PI * 0.5, 0]}
                    style={{
                        opacity: 1
                    }}
                >
                    { (currentView === VIEW_MUSIC) ? 
                        <iframe src="https://songs.prathik-karanth.com/"></iframe> 
                        : null 
                    }
                    
                </Html>
        
            {(currentView === VIEW_STUDIO) ?
            <Html
                position={ [ 0.6, 1.3, 1.5 ] }
                wrapperClass='label'
                
            >
                <button className='astext' onClick={() => setCurrentView(VIEW_MUSIC)}>My Music</button>
            </Html>
            : null
            }

            {(currentView === VIEW_MUSIC) ? 
                <Html
                    position={ [ 0.8, 1.0, 1.35 ] }
                    wrapperClass='label'

                >
                    <button className='astext' onClick={() => setCurrentView(VIEW_GOTO_STUDIO)}>Back</button>
                </Html> 
                : null
            }

            {(currentView === VIEW_STUDIO) ? 
                <Html
                    position={ [ 0, 1.3, 4 ]  }
                    wrapperClass='viewChange'

                >
                    <button className='astext' onClick={() => setCurrentView(VIEW_GOTO_BEDROOM)}> <i class="arrow left"></i> Enter Bedroom </button>
                </Html> 
                : null
            }


                
            

            <Html>
                <audio src="./sounds/fast_woosh.wav" ref={audioRef}></audio>     
            </Html>
        </Suspense>
    </>
}