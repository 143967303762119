import { shaderMaterial, Html, useHelper, BakeShadows, ContactShadows, useAnimations, Text, Float } from '@react-three/drei'
import { useRef, useState, useEffect } from 'react'
import { act, useFrame, extend } from '@react-three/fiber'
import * as THREE from 'three'
import gsap from 'gsap'
import { Suspense } from 'react'
import useViews from './stores/useViews'
import coffeeSmokeVertex from './shaders/CoffeeSmoke/vertex.glsl'
import coffeeSmokeFragment from './shaders/CoffeeSmoke/fragment.glsl'


const CoffeeSmokeMaterial = shaderMaterial(
    {
        uTime: 0,
        uResolution: new THREE.Vector2(0.5, 0.75),
    },
    coffeeSmokeVertex,
    coffeeSmokeFragment,
    (material) => {
        material.blending = THREE.AdditiveBlending
    }
)

extend({ CoffeeSmokeMaterial })

export default function Bedroom({model})
{
    const iframeRef = useRef()
    const [isVisible, setIsVisible] = useState(true)

    const coffeSmokeRef = useRef()
    
    // const dirLightRef = useRef()
    // // useHelper(dirLightRef, THREE.PointLightHelper, 0.5, 'hotpink')
 
    model.scene.traverse((child) => {

        if (child.isMesh) {
            child.castShadow = true
            child.receiveShadow = true
        }
    })

    const animations = useAnimations(model.animations, model.scene)
    
    // Zustand Global State Management
    const studio = useViews( (state) => { return state.studio } )
    const bedroom = useViews( (state) => { return state.bedroom } )
    const profile = useViews( (state) => { return state.profile } )
  
    

    function dampedParallax(cursor, theta) {
        let target_theta = cursor.x * 0.5;

        const THETA_CLAMP_AMOUNT = 0.1;
        target_theta = Math.min(Math.max(target_theta, -THETA_CLAMP_AMOUNT), THETA_CLAMP_AMOUNT)

        const d_theta = target_theta - theta;
        theta += d_theta * 0.05

        if(d_theta < 0.0) {
            theta = Math.max(theta, target_theta)
        } else if(d_theta > 0.0) {
            theta = Math.min(theta, target_theta)
        }  
        return theta;
    }

    function parallaxEffect(targetPosition, currentLookAt)
    {
        const updatedTheta = dampedParallax(mouse, theta)
        setTheta(updatedTheta)

        const cameraFacingVec = new THREE.Vector3().subVectors(targetPosition, currentLookAt)
        const distanceBetweenCamAndLookAt = cameraFacingVec.length()
        cameraFacingVec.normalize()
        const upVec = new THREE.Vector3(0, 1, 0)
        const rightVec = new THREE.Vector3().crossVectors(cameraFacingVec, upVec)
        rightVec.normalize();

        currentCamPosition.set(currentLookAt.x, currentLookAt.y, currentLookAt.z)
        currentCamPosition.addScaledVector(rightVec,        Math.sin(updatedTheta) * distanceBetweenCamAndLookAt)
        currentCamPosition.addScaledVector(cameraFacingVec, Math.cos(updatedTheta) * distanceBetweenCamAndLookAt)
    }

    const VIEW_BEDROOM = 0;
    const VIEW_PROFILE = 1;
    const VIEW_GOTO_STUDIO = 2;
    const VIEW_STUDIO = 3;
    const VIEW_GOTO_BEDROOM = 4;
    const VIEW_INTO_BEDROOM = 5;


    const [previousView, setPreviousView] = useState(-1)
    const [currentView, setCurrentView] = useState(VIEW_INTO_BEDROOM)
    const [currentLookAt, setCurrentLookAt] = useState(new THREE.Vector3(0, 0, 0))
    const [currentCamPosition, setCurrentCamPosition] = useState(new THREE.Vector3(-5.9, 3.3, 6))
    const [currentBgColor, setCurrentBgColor] = useState(new THREE.Color('#222430'))

    const playSound = () => {
        
        new Audio('./sounds/fast_woosh.wav').play()
        
    }
    


    const [mouse, setMouse] = useState({x:0, y:0});
    const handleMouseMove = (evt) => {
        setMouse({
            x: (evt.clientX / window.innerWidth) * 2 - 1,   
            y: -(evt.clientY / window.innerHeight) * 2 + 1    
        })
    }
    
    useEffect(() => {
    

        window.addEventListener('mousemove', handleMouseMove);
    
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        }

    }, []);

    useEffect(() => {
        // let startAfter = 0;

        for(const name in animations.actions) {
            const action = animations.actions[name]

            // check if name ends with "Inflate"
            if(name.endsWith("Inflate")){
                
                
                action.repetitions = 1
                action.clampWhenFinished = true
                action.play()
                // setTimeout(() => {
                //     action.play()
                // }, startAfter)
                // startAfter += 100
            } else {
                action.stop()
            }
        }
    }, [])


    const [theta, setTheta] = useState(0.0)
    useFrame((state, delta) => {
        const t = state.clock.getElapsedTime()
        let targetPosition;
        let targetLookAtVec;
        let newBgColor = new THREE.Color()

        
        //CoffeSmoke
        coffeSmokeRef.current.uTime += delta
        
        //Camera Positioning for different views
        switch(currentView) {
            case VIEW_BEDROOM:
                bedroom()
                targetPosition = new THREE.Vector3(-2.9, 3.3, 6)
                targetLookAtVec = new THREE.Vector3(0, 0, 0)
            
                
                //Camera Parallax Effect
                parallaxEffect(targetPosition, currentLookAt)
                break;

            case VIEW_PROFILE:
                targetPosition = new THREE.Vector3(0.1, 1.2, 1.4)
                targetLookAtVec = new THREE.Vector3(0.9, 1.2, 1.4)

                gsap.to(currentCamPosition, {
                    x: targetPosition.x,
                    y: targetPosition.y,
                    z: targetPosition.z,
                    duration: 1,
                    ease: 'power4.out'
                })

                gsap.to(currentLookAt, {
                    duration: 1,
                    x: targetLookAtVec.x,
                    y: targetLookAtVec.y,
                    z: targetLookAtVec.z,
                    onComplete: () => {
                        gsap.to(iframeRef.current.style, {
                            opacity: 1.0,
                            duration: 3.0,   
                            
                        })
                    },
                    ease: 'power4.out'
                
                })
                profile()

                break;

            case VIEW_GOTO_STUDIO:
                targetPosition = new THREE.Vector3(-5.9, 3.3, 6)
                
                const homePosition = new THREE.Vector3(-2.9, 3.3, 6)
                parallaxEffect(homePosition, currentLookAt)

                
                gsap.to(currentCamPosition, {
                    x: targetPosition.x,
                    y: targetPosition.y,
                    z: targetPosition.z,
                    duration: 1,
                    ease: 'power4.out',
                    
                })


                for(const name in animations.actions) {
                    // check if name ends with "Deflate"
                    const action = animations.actions[name]
                    if(name.endsWith("Deflate")){             
                        action.play()
                        action.repetitions = 1
                        action.clampWhenFinished = true
                    }
                    else {
                        
                        action.stop()
                    }
                }
                
                playSound()
                
                setCurrentView(VIEW_STUDIO)
                break;

            case VIEW_STUDIO:
                
                setTimeout(() => {
                    setIsVisible(false)
                    studio()
                }, 700)

                break;
            
            case VIEW_GOTO_BEDROOM:
                
                targetPosition = new THREE.Vector3(-2.9, 3.3, 6)
                targetLookAtVec = new THREE.Vector3(0, 0, 0)
                if(previousView !== VIEW_GOTO_BEDROOM) {
                    const gsapCameraPosition = currentCamPosition.clone();
                    gsap.to(gsapCameraPosition, {
                        x: targetPosition.x,
                        y: targetPosition.y,
                        z: targetPosition.z,
                        duration: 1,
                        onUpdate: function() {
                            setCurrentCamPosition(gsapCameraPosition.clone())
                        },
                        ease: 'power4.out'
                    })
    
                    const gsapLookAt = currentLookAt.clone();
                    gsap.to(gsapLookAt, {
                        duration: 1,
                        x: targetLookAtVec.x,
                        y: targetLookAtVec.y,
                        z: targetLookAtVec.z,
                        onUpdate: function() {
                            setCurrentLookAt(gsapLookAt.clone())
                        },
                        ease: 'power4.out'
                    })
    
                    
                    setTheta(0.0)
                }
                

                if(previousView !== VIEW_GOTO_BEDROOM) {
                    setTimeout(() => {                       
                        setCurrentView(VIEW_BEDROOM)
                    }
                    , 600)
                }


                break;
            case VIEW_INTO_BEDROOM:
                targetPosition = new THREE.Vector3(-2.9, 3.3, 6)
                targetLookAtVec = new THREE.Vector3(0, 0, 0)
                
                if(previousView !== VIEW_INTO_BEDROOM) {
                    const cameraPositionGsap = currentCamPosition.clone();
                    gsap.to(cameraPositionGsap, {
                        x: targetPosition.x,
                        y: targetPosition.y,
                        z: targetPosition.z,
                        duration: 1.5,
                        ease: 'power4.inOut', 
                        onUpdate: () => { 
                            setCurrentCamPosition(cameraPositionGsap)
                        },
                        onComplete: () => {
                            setCurrentView(VIEW_BEDROOM)
                            
                        }
                    })
                }
                newBgColor.lerpColors(currentBgColor, new THREE.Color('#49403c'), 0.05) 
                setCurrentBgColor(newBgColor)

                break;   
        }        
 

        state.camera.position.x = currentCamPosition.x
        state.camera.position.y = currentCamPosition.y
        state.camera.position.z = currentCamPosition.z
        state.camera.lookAt(currentLookAt)
        state.camera.updateProjectionMatrix()
          
        setPreviousView(currentView)
    })

    

    return <>

        <color attach="background" args={[currentBgColor]} />
 
    
        <Suspense fallback={null}>
            

            {
                isVisible ?
                <primitive object={ model.scene} /> 
                : null
            }
            
                
                
                <Html
                    ref={ iframeRef }
                    position={ [ 1.0, 1.216, 1.455 ] }
                    transform
                    wrapperClass='aboutScreen'
                    distanceFactor={ 0.26 }
                    rotation={[0, - Math.PI * 0.5, 0]}
                    style={{
                        opacity: 0
                    }}
                >
                    { (currentView === VIEW_PROFILE) ? 
                        // <iframe src="./PortfolioHome.html"></iframe> 
                        <iframe src="https://lite.prathik-karanth.com/"></iframe> 
                        : null 
                    }
                    
                </Html>
                
                          
            
        </Suspense>


        {(currentView === VIEW_BEDROOM) ?
            <Html
                position={ [ 0.6, 1.3, 1.5 ] }
                wrapperClass='label'
                
            >
                <button className='astext' onClick={() => setCurrentView(VIEW_PROFILE)}>My Profile</button>
            </Html>
            : null
        }

        {(currentView === VIEW_PROFILE) ? 
            <Html
                position={ [ 0.8, 1.0, 1.7 ] }
                wrapperClass='label'

            >
                <button className='astext' onClick={() => setCurrentView(VIEW_GOTO_BEDROOM)}>Back</button>
            </Html> 
            : null
        }

        {(currentView === VIEW_BEDROOM) ? 
            <Html
            position={ [ 0, 1.3, 4 ] }
            wrapperClass='viewChange'
                
            >
                <button className='astext' onClick={() => setCurrentView(VIEW_GOTO_STUDIO)}>Enter Studio <i class="arrow right"></i> </button>
            </Html>
            : null
        } 

        {(currentView === VIEW_BEDROOM || VIEW_PROFILE) ? 
            <mesh position={ [0.8, 0.99, 1.92] } scale={ 0.35 } rotation={[0, -Math.PI * 0.5, 0]}> 
            <planeGeometry attach="geometry" args={[0.5, 0.75]} />
            <coffeeSmokeMaterial ref={ coffeSmokeRef } />
        </mesh>
            : null
        } 

    </>
} 