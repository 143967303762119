import Bedroom from './Bedroom.jsx'
import Studio from './Studio.jsx'
import Room from './Room.jsx'
import useViews from './stores/useViews'

import { EffectComposer } from '@react-three/postprocessing'

import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { useRef} from 'react'
import { useFrame } from '@react-three/fiber'


export default function Experience({started})
{
    const currentPhase = useViews( state => state.phase )

    const roomModel = useLoader(
        GLTFLoader,
        './models/Room.glb',
        (loader) => {
            const dracoLoader = new DRACOLoader()
            dracoLoader.setDecoderPath('./draco/')
            loader.setDRACOLoader(dracoLoader)    
        }
    )

    const studioModel = useLoader(
        GLTFLoader,
        './models/studio2_testing.glb',
        (loader) => {
            const dracoLoader = new DRACOLoader()
            dracoLoader.setDecoderPath('./draco/')
            loader.setDRACOLoader(dracoLoader)    
        }
    )

    const bedroomModel = useLoader(
        GLTFLoader,
        './models/house2_withoutWall.glb',
        (loader) => {
            const dracoLoader = new DRACOLoader()
            dracoLoader.setDecoderPath('./draco/')
            loader.setDRACOLoader(dracoLoader)
            
        }
    )

    useFrame((state) => {
        const aspect = window.innerWidth / window.innerHeight
        const zoomFactor = aspect / 1.7
        state.camera.zoom = zoomFactor
    });

    

    return <>

        <EffectComposer multisampling={ 8 }>
        
        </EffectComposer>

        

        { (started) ? 
            
            <Room model={ roomModel } />
            : null 
        }

        

        { (currentPhase === 'VIEW_BEDROOM' || currentPhase === 'VIEW_PROFILE' || currentPhase === 'VIEW_BEDROOM') ? 
            
            (started) ? 

                <Bedroom model={ bedroomModel } />
                : null
            
            : null 
        }


        { (currentPhase === 'VIEW_STUDIO' || currentPhase === 'VIEW_MUSIC') ? 
            
            <Studio model={ studioModel } />
            : null 
        }


        
            
    </>
}