import { useProgress } from "@react-three/drei";

export default function LoadingScreen({ started, onStarted }){
  const { progress } = useProgress();

  return (
    <div className={`loadingScreen ${started ? "loadingScreen--started" : ""}`}>
      <div className="loadingScreen__progress">

      {(progress < 100) ? <div
          className="loadingScreen__progress__value"
          style={{
            width: `${progress}%`,
          }}
        /> : null} 

        
      </div>
        <div className="loadingScreen__board">
          {(progress < 100) ? <h1 className="loadingScreen__title">LOADING</h1> : null} 

          {(progress == 100) ? <button
            className="loadingScreen__button"
            disabled={progress < 100}
            onClick={onStarted}
          >START</button> : null} 
          
          
          {/* <button
            className="loadingScreen__button"
            disabled={progress < 100}
            onClick={onStarted}
          >
            START
          </button> */}
        </div>

        <div className={`lite-version-text ${started ? "lite-version-text--started" : ""}`}> <p>For lite version  <a href="https://lite.prathik-karanth.com/"> click here </a></p></div>
      
    </div>
  );
};